import { render, staticRenderFns } from "./tokenlogin.vue?vue&type=template&id=7268029d&scoped=true&"
import script from "./tokenlogin.vue?vue&type=script&lang=js&"
export * from "./tokenlogin.vue?vue&type=script&lang=js&"
import style0 from "./tokenlogin.vue?vue&type=style&index=0&id=7268029d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7268029d",
  null
  
)

export default component.exports