<template>
	<div class="tokenlogin">
		<div class="first-loading-wrp">
			<div class="loading-wrp">
				<span class="dot dot-spin">
					<i></i>
					<i></i>
					<i></i>
					<i></i>
				</span>
			</div>
			<h2>悦融易融资超市（资金端）欢迎你</h2>
		</div>
	</div>
</template>

<script>
import { getUserInfoByToken, roleList } from '@/api/index.js';
export default {
	name: 'tokenlogin',
	data() {
		return {};
	},
	created() {
		this.getUserInfoByToken({ token: this.$route.query.token });
	},
	methods: {
		async getUserInfoByToken(data) {
			const res = await getUserInfoByToken(data);
			if (res.returncode === 0) {
				localStorage.setItem('userInfo', JSON.stringify(res));
				this.$store.commit('setUserInfo', res);
				this.roleList({ userId: res.userId });
			} else {
				this.$message({
					message: res.message,
					type: 'warning',
				});
			}
		},
		async roleList(data) {
			const res = await roleList(data);
			debugger;
			const RouteView = {
				name: 'RouteView',
				render: (h) => h('router-view'),
			};
			let routerinfo = [
				{
					path: '/',
					name: 'home',
					component: 'views/home/home',
					redirect: '/index',
					children: [
						{
							path: '/index',
							name: 'index',
							component: RouteView,
							redirect: '/index/info',
							meta: { name: '首页' },
							children: [
								{
									path: '/index/info',
									name: 'indexInfo',
									component: 'views/index/index',
									meta: {
										name: '首页',
										icon: 'icon-shouye',
										isShow: true,
									},
								},
								{
									path: '/index/mycustomer',
									name: 'myCustomer',
									component: 'views/mycustomer/mycustomer',
									meta: {
										name: '悦融易推荐给我的客户',
										icon: 'icon-kehuguanli',
										isShow: true,
									},
								},
								{
									path: '/index/companycustomer',
									name: 'companyCustomer',
									component:
										'views/companycustomer/companycustomer',
									meta: {
										name: '我推荐给悦融易的客户',
										icon: 'icon-kehu',
										isShow: true,
									},
								},
								{
									path: '/index/inquiry',
									name: 'inquiry',
									component: 'views/inquiry/inquiry',
									meta: {
										name: '悦融易客户询单',
										icon: 'icon-xundan',
										isShow: true,
									},
								},
								{
									path: '/index/scheme',
									name: 'scheme',
									component: 'views/scheme/scheme',
									meta: {
										name: '客户的方案',
										icon: 'icon--fanganguanli',
										isShow: true,
									},
								},
								{
									path: '/index/approval',
									name: 'approval',
									component: 'views/approval/approval',
									meta: {
										name: '客户的批复',
										icon: 'icon-pifu',
										isShow: true,
									},
								},
								{
									path: '/index/dataanalysis',
									name: 'dataanalysis',
									component:
										'views/dataanalysis/dataanalysis',
									meta: {
										name: '银行流水分析',
										icon: 'icon-yinhangliushui',
										isShow: true,
									},
								},
								{
									path: '/index/financial',
									name: 'financial',
									component: 'views/financial/financial',
									meta: {
										name: '财务数据分析',
										icon: 'icon-ziyuanx',
										isShow: true,
									},
								},
								{
									path: '/index/myproduct',
									name: 'myproduct',
									component: 'views/myproduct/myproduct',
									meta: {
										name: '我机构产品',
										icon: 'icon-productManagement',
										isShow: true,
									},
								},

								{
									path: '/index/branch',
									name: 'branch',
									component: 'views/branch/branch',
									meta: {
										name: '我的网点',
										icon: 'icon-wangdian',
										isShow: true,
									},
								},
								{
									path: '/index/myinfo',
									name: 'userinfoInfo',
									component: 'views/userinfo/userinfo',
									meta: {
										name: '我的信息',
										icon: 'icon-wodexinxi_jibenxinxi',
										isShow: true,
									},
								},
								{
									path: '/index/notice',
									name: 'notice',
									component: 'views/notice/notice',
									meta: {
										name: '合作须知',
										icon: 'icon-lvshigaozhihan',
										isShow: true,
									},
								},
								{
									path: '/index/changepassword',
									name: 'changepassword',
									component:
										'views/changepassword/changepassword',
									meta: {
										name: '修改密码',
										icon: 'icon-iconfont11',
										isShow: false,
									},
								},
								{
									path: '/index/notification',
									name: 'notification',
									component:
										'views/notification/notification',
									meta: {
										name: '通知管理',
										isShow: false,
									},
								},
							],
						},
					],
				},
			];
			this.$store.commit('asyncRouter', routerinfo);
			localStorage.setItem('asyncRouter', JSON.stringify(routerinfo));
			this.$router.replace('/index');
		},
	},
};
</script>

<style lang="scss" scoped>
.first-loading-wrp {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 90vh;
	min-height: 90vh;
}

.first-loading-wrp > h1 {
	font-size: 30px;
	font-weight: bolder;
}

.first-loading-wrp .loading-wrp {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 98px;
}

.dot {
	position: relative;
	box-sizing: border-box;
	display: inline-block;
	width: 64px;
	height: 64px;
	font-size: 64px;
	transform: rotate(45deg);
	animation: antRotate 1.2s infinite linear;
}

.dot i {
	position: absolute;
	display: block;
	width: 28px;
	height: 28px;
	background-color: #1890ff;
	border-radius: 100%;
	opacity: 0.3;
	transform: scale(0.75);
	transform-origin: 50% 50%;
	animation: antSpinMove 1s infinite linear alternate;
}

.dot i:nth-child(1) {
	top: 0;
	left: 0;
}

.dot i:nth-child(2) {
	top: 0;
	right: 0;
	-webkit-animation-delay: 0.4s;
	animation-delay: 0.4s;
}

.dot i:nth-child(3) {
	right: 0;
	bottom: 0;
	-webkit-animation-delay: 0.8s;
	animation-delay: 0.8s;
}

.dot i:nth-child(4) {
	bottom: 0;
	left: 0;
	-webkit-animation-delay: 1.2s;
	animation-delay: 1.2s;
}

@keyframes antRotate {
	to {
		-webkit-transform: rotate(405deg);
		transform: rotate(405deg);
	}
}

@-webkit-keyframes antRotate {
	to {
		-webkit-transform: rotate(405deg);
		transform: rotate(405deg);
	}
}

@keyframes antSpinMove {
	to {
		opacity: 1;
	}
}

@-webkit-keyframes antSpinMove {
	to {
		opacity: 1;
	}
}
</style>
